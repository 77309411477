@import '../Common/colors.dashboard.scss';

.mapboxgl-popup-content {
  background-color: $white !important;
  color: $card-title !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: $white !important;
}

.mapboxgl-popup-close-button,
.mapboxgl-popup-close-button:hover {
  display: none;
  // z-index: -1 !important;
}

.total-container {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
}

.margin-top-6 {
  margin-top: 7.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.cards-title {
  font-family: 'Inter';
  align-items: flex-start;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  height: 50px;
  font-size: 1.15rem;
  margin-top: 1rem;
  color: $card-title;
}

.dashboard-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0 2rem 0 2rem;

  .header-and-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 15px 0 15px;
    font-family: 'Inter-Regular';
    font-size: 1.4rem;
    font-weight: 600;
  }

  .stastics-cards-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    .stastic-card-row {
      width: 100%;

      .stastic-card-col {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0 1.2rem 0 1.2rem;

    .radio-selection {
      .ant-radio-button-wrapper {
        height: 35px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: rgba(58, 53, 65, 0.5);
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: transparent !important;
      }

      .overall {
        border-radius: 5px 0 0 5px !important;
      }

      .mine {
        border-radius: 0 5px 5px 0 !important;
      }
    }
  }

  .center {
    align-items: center;
    justify-content: center;
  }

  .height-pie-rem {
    height: 26.2rem;

    .apexcharts-legend {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between !important;
    }

    .apexcharts-legend-series {
      display: flex !important;
      width: 38%;
    }
  }

  .height-bar-rem {
    height: 29rem;
  }

  .height-map-rem {
    height: 510px;
  }

  .stastics-and-charts-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .stastic-card-row {
      width: 100%;
      .stastic-card-col {
        .stastics-and-pie-card {
          display: flex;
          flex-direction: column;
          background-color: $white;
          border-radius: 10px;
          padding: 0 1rem 0.7rem 1rem;
          width: 100%;

          .margin-top-2 {
            width: 100%;
            margin-top: 2rem;
          }
          .dashboard-login-buttons {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            .login-button {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              font-size: 1rem;
              background-color: #004d61;
              border: none;
              cursor: pointer;
              padding: 10px 16px;
              color: #fff;
              border-radius: 8px;
              min-width: 350px;
              max-width: 100%;
              text-align: center;
              transition: 0.5s;

              &:hover {
                background-color: #003b50;
              }
            }
          }

          .title-section {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            padding: 1rem 1rem 0 1rem;

            .title {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              width: 90%;
              font-size: 1.15rem;
              color: $card-title;
            }

            .info-container {
              display: flex;
              flex-direction: row;
              width: 10%;
              height: 30px;
              align-items: center;
              justify-content: flex-end;
            }
          }

          .total-programme-details {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 150px;
            margin-top: -0.5rem;

            .details {
              display: flex;
              flex-direction: column;
              width: 70%;
              padding: 0 1rem 0 0.8rem;

              .detail {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.375rem;
                padding-left: 0.125rem;
                color: $card-title;
                margin-bottom: 0.5rem;
              }

              .value {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 22px;
                padding-left: 0.125rem;
                padding: 0;
                color: $color-primary;
              }
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 30%;

              svg {
                fill: $color-primary;
              }

              img {
                height: 80px;
              }
            }
          }

          .total-programme-extra-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: 100px;
            width: 100%;
            margin-top: -1rem;
            margin-bottom: 0.5rem;
            padding: 0 0.5rem 0 0.5rem;

            .rejected-details,
            .transfered-details {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .icon {
                display: flex;
                flex-direction: row;
                width: 20%;

                .icon-container {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50px;
                  width: 2.813rem;
                  height: 2.813rem;
                }

                .reject {
                  background-color: rgba(255, 166, 166, 0.4);
                }

                .transfer {
                  background-color: rgba(254, 241, 173, 0.5);
                }

                .pending {
                  background-color: rgba(164, 162, 168, 0.5);
                }

                .authorized {
                  background-color: rgba(185, 226, 244, 0.4);
                }
              }

              .details {
                display: flex;
                flex-direction: column;
                width: 80%;
                height: 3.438rem;

                .label-and-value {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: space-between;

                  .label {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875rem;
                    color: $card-labels;
                  }

                  .value {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    height: 30px;
                    font-size: 1.5rem;
                    margin-top: -8px;
                    color: $card-title;
                  }
                }
              }
            }
          }

          .stage-legends {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
          }

          .updated-on {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-start;
            padding: 0 1rem 0 0.8rem;
            height: 50px;

            .updated-moment-container {
              display: flex;
              flex-direction: row;
              font-size: 0.75rem;
              border-radius: 3.125rem;
              padding: 0.05rem 0.8rem 0.05rem 0.8rem;
              color: $color-primary;
              background-color: rgba($color: #b9e2f4, $alpha: 0.4);
            }
          }

          .pie-charts-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 1rem 0 1rem;
            .pie-charts-title {
              display: flex;
              flex-direction: row;
              font-family: 'Inter';
              align-items: flex-start;
              justify-content: flex-start;
              font-style: normal;
              font-weight: 600;
              height: 50px;
              font-size: 1.15rem;
              margin-top: 1rem;
              color: $card-title;

              .unit {
                display: flex;
                margin: 0 0 0 0.5rem;
                font-family: 'Inter';
                font-size: 1.15rem;
                font-weight: 500;
                color: rgba(58, 53, 65, 0.5);
              }
            }

            .info-container {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }

          .pie-charts-section {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }

  .stastics-and-pie-card:hover {
    box-shadow: rgba(0, 5, 18, 0.2) 0px 8px 24px;
  }

  .stastics-charts-container-total-programmes {
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 60px 10px 25px 15px;

    .stastic-charts-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;

      .stastic-charts-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .chart-card {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 400px;
          background: #f6f6f6;
          box-shadow: rgba(106, 106, 106, 0.2) 0px 8px 24px;
          border-radius: 10px;
          padding: 10px 10px 10px 10px;
        }

        .chart-card:hover {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: #f1f1f1;
          box-shadow: rgba(124, 124, 124, 0.2) 0px 8px 24px;
          border-radius: 10px;
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }

  .stastics-charts-container-certified-programmes {
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 5px 10px 25px 15px;
    .stastic-charts-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;

      .stastic-charts-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .chart-card {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 400px;
          background: #f6f6f6;
          box-shadow: rgba(106, 106, 106, 0.2) 0px 8px 24px;
          border-radius: 10px;
          padding: 10px 10px 10px 10px;
        }

        .chart-card:hover {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: #f1f1f1;
          box-shadow: rgba(124, 124, 124, 0.2) 0px 8px 24px;
          border-radius: 10px;
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }

  .stastics-pie-charts-container {
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 5px 10px 25px 15px;
    .stastic-charts-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;

      .stastic-charts-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        .chart-card {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 400px;
          background: #f6f6f6;
          box-shadow: rgba(106, 106, 106, 0.2) 0px 8px 24px;
          border-radius: 10px;
          padding: 10px 10px 10px 10px;
        }

        .chart-card:hover {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: #f1f1f1;
          box-shadow: rgba(124, 124, 124, 0.2) 0px 8px 24px;
          border-radius: 10px;
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }

  .maps-container {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    margin: 25px 20px 25px 15px;
    height: auto;
    .maps-container-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;

      .maps-container-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
